/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type OverviewImageInput = {
  entId: string,
  objId: string,
  fileKey: string,
  idx: number,
};

export type LocationImageInput = {
  entId: string,
  objId: string,
  cuId: string,
  fileKey: string,
};

export type CrackImageInput = {
  entId: string,
  objId: string,
  cuId: string,
  stripId: string,
  fileKey: string,
};

export type CULocationInput = {
  entId: string,
  objId: string,
  cuId: string,
  posX: number,
  posY: number,
};

export type Dashboard = {
  __typename: "Dashboard",
  tosVersion: string,
  isMaintenance: boolean,
  createdAt: string,
  updatedAt: string,
};

export type Customer = {
  __typename: "Customer",
  entId: string,
  custName: string,
  buName?: string | null,
  applyWatermelon?: boolean | null,
  createdAt: string,
  updatedAt: string,
};

export type Entity = {
  __typename: "Entity",
  entId: string,
  createdAt: string,
  updatedAt: string,
};

export type Object = {
  __typename: "Object",
  id: string,
  entId: string,
  state: State,
  group?: string | null,
  objName: string,
  objType: ObjectType,
  count: number,
  img:  Array<Image | null >,
  createdAt: string,
  updatedAt: string,
};

export enum State {
  ACTIVE = "ACTIVE",
  DISABLED = "DISABLED",
  MAINTENANCE = "MAINTENANCE",
  LOOSE = "LOOSE",
  HIDDEN = "HIDDEN",
  BROKEN = "BROKEN",
  ARCHIVED = "ARCHIVED",
}


export enum ObjectType {
  OBJECT = "OBJECT",
  ASSET = "ASSET",
  BRIDGE = "BRIDGE",
  CRANE = "CRANE",
}


export type Image = {
  __typename: "Image",
  id?: string | null,
  fileKey: string,
};

export type CU = {
  __typename: "CU",
  id: string,
  objId: string,
  entId: string,
  state: State,
  group?: string | null,
  count: number,
  img: Image,
  posX: number,
  posY: number,
  installationDate?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type Strip = {
  __typename: "Strip",
  id: string,
  cuId: string,
  objId: string,
  entId: string,
  state: State,
  config: Config,
  threshold: Threshold,
  location: string,
  stripType: StripType,
  mountedType?: MountedType | null,
  isReversed?: boolean | null,
  startAngle?: number | null,
  radius?: number | null,
  count: number,
  img: Image,
  pipeImg?: Image | null,
  installationDate?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type Config = {
  __typename: "Config",
  avg: number,
  beginDate?: string | null,
  algoType: AlgoType,
  algoVersion: string,
  lastDataPoint?: string | null,
  minB: number,
  maxB: number,
};

export enum AlgoType {
  OSF2_TWOCOMP = "OSF2_TWOCOMP",
  LMV = "LMV",
}


export type Threshold = {
  __typename: "Threshold",
  lower: number,
  upper: number,
  min?: number | null,
  max?: number | null,
  anomaly: boolean,
  alarm: number,
};

export enum StripType {
  STANDARD = "STANDARD",
  FLEXIBLE = "FLEXIBLE",
}


export enum MountedType {
  FLAT = "FLAT",
  PIPE = "PIPE",
}


export type Zone = {
  __typename: "Zone",
  id: string,
  stripId: string,
  cuId: string,
  objId: string,
  entId: string,
  threshold: Threshold,
  posX: number,
  posY: number,
  createdAt: string,
  updatedAt: string,
};

export type GraphPoint = {
  __typename: "GraphPoint",
  datetime: string,
  value?: number | null,
};

export type Report = {
  __typename: "Report",
  vid: string,
  strip: string,
  zone: string,
  datetime: string,
  value: number,
  daily?: number | null,
  weekly?: number | null,
};

export type Battery = {
  __typename: "Battery",
  vid: string,
  datetime: string,
  value: number,
};

export type Stat = {
  __typename: "Stat",
  vid: string,
  battery?: number | null,
  daily?: number | null,
  weekly?: number | null,
};

export type SetOverviewImageMutationVariables = {
  input: OverviewImageInput,
};

export type SetOverviewImageMutation = {
  setOverviewImage: number,
};

export type SetLocationImageMutationVariables = {
  input: LocationImageInput,
};

export type SetLocationImageMutation = {
  setLocationImage: number,
};

export type SetCrackImageMutationVariables = {
  input: CrackImageInput,
};

export type SetCrackImageMutation = {
  setCrackImage: number,
};

export type SetCULocationMutationVariables = {
  input: CULocationInput,
};

export type SetCULocationMutation = {
  setCULocation: number,
};

export type GetDashboardConfigQueryVariables = {
};

export type GetDashboardConfigQuery = {
  getDashboardConfig?:  {
    __typename: "Dashboard",
    tosVersion: string,
    isMaintenance: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetCustomersQueryVariables = {
  sub: string,
};

export type GetCustomersQuery = {
  getCustomers?:  Array< {
    __typename: "Customer",
    entId: string,
    custName: string,
    buName?: string | null,
    applyWatermelon?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null > | null,
};

export type GetObjectsQueryVariables = {
  entId: string,
};

export type GetObjectsQuery = {
  getObjects?:  Array< {
    __typename: "Object",
    id: string,
    entId: string,
    state: State,
    group?: string | null,
    objName: string,
    objType: ObjectType,
    count: number,
    img:  Array< {
      __typename: "Image",
      id?: string | null,
      fileKey: string,
    } | null >,
    createdAt: string,
    updatedAt: string,
  } | null > | null,
};

export type GetCUsQueryVariables = {
  entId: string,
  objId: string,
};

export type GetCUsQuery = {
  getCUs?:  Array< {
    __typename: "CU",
    id: string,
    objId: string,
    entId: string,
    state: State,
    group?: string | null,
    count: number,
    img:  {
      __typename: "Image",
      id?: string | null,
      fileKey: string,
    },
    posX: number,
    posY: number,
    installationDate?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null > | null,
};

export type GetStripsQueryVariables = {
  entId: string,
  objId: string,
  cuId: string,
};

export type GetStripsQuery = {
  getStrips?:  Array< {
    __typename: "Strip",
    id: string,
    cuId: string,
    objId: string,
    entId: string,
    state: State,
    config:  {
      __typename: "Config",
      avg: number,
      beginDate?: string | null,
      algoType: AlgoType,
      algoVersion: string,
      lastDataPoint?: string | null,
      minB: number,
      maxB: number,
    },
    threshold:  {
      __typename: "Threshold",
      lower: number,
      upper: number,
      min?: number | null,
      max?: number | null,
      anomaly: boolean,
      alarm: number,
    },
    location: string,
    stripType: StripType,
    mountedType?: MountedType | null,
    isReversed?: boolean | null,
    startAngle?: number | null,
    radius?: number | null,
    count: number,
    img:  {
      __typename: "Image",
      id?: string | null,
      fileKey: string,
    },
    pipeImg?:  {
      __typename: "Image",
      id?: string | null,
      fileKey: string,
    } | null,
    installationDate?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null > | null,
};

export type GetZonesQueryVariables = {
  entId: string,
  objId: string,
  cuId: string,
  stripId: string,
};

export type GetZonesQuery = {
  getZones?:  Array< {
    __typename: "Zone",
    id: string,
    stripId: string,
    cuId: string,
    objId: string,
    entId: string,
    threshold:  {
      __typename: "Threshold",
      lower: number,
      upper: number,
      min?: number | null,
      max?: number | null,
      anomaly: boolean,
      alarm: number,
    },
    posX: number,
    posY: number,
    createdAt: string,
    updatedAt: string,
  } | null > | null,
};

export type GetGraphPointsQueryVariables = {
  vid: string,
  strip: string,
  zone: string,
  algo: AlgoType,
  limit?: number | null,
};

export type GetGraphPointsQuery = {
  getGraphPoints?:  Array< {
    __typename: "GraphPoint",
    datetime: string,
    value?: number | null,
  } | null > | null,
};

export type GetReportsQueryVariables = {
  vids: Array< string >,
  algo: AlgoType,
};

export type GetReportsQuery = {
  getReports?:  Array< {
    __typename: "Report",
    vid: string,
    strip: string,
    zone: string,
    datetime: string,
    value: number,
    daily?: number | null,
    weekly?: number | null,
  } | null > | null,
};

export type GetBatteriesQueryVariables = {
  vids?: Array< string > | null,
};

export type GetBatteriesQuery = {
  getBatteries?:  Array< {
    __typename: "Battery",
    vid: string,
    datetime: string,
    value: number,
  } | null > | null,
};

export type GetStatsQueryVariables = {
  vids?: Array< string > | null,
};

export type GetStatsQuery = {
  getStats?:  Array< {
    __typename: "Stat",
    vid: string,
    battery?: number | null,
    daily?: number | null,
    weekly?: number | null,
  } | null > | null,
};
