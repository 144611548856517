import { useAuthenticator } from '@aws-amplify/ui-react';
import { FC, createContext, useContext, useEffect, useState } from 'react';

import * as api from 'src/api';
import { CUnitItem, CustomerItem, StripItem } from 'src/models';
import {
  AlgoType,
  Battery,
  CU,
  Dashboard,
  GraphPoint,
  Image,
  Object,
  Report,
  Stat,
  State,
  Zone
} from 'src/models/API';
import { ActivityHistory, ActivityPoint, activityReducer, getActivity } from 'src/models/ActivityData';

interface IAPIContext {
  // States
  dashboard: Dashboard;
  customers: CustomerItem[];

  // Queries
  getOverviewData: (obj: Object, setState: (value: CUnitItem[]) => void) => void;
  getReportData: (cUnits: CUnitItem[], algo: AlgoType, setState: (value: Report[]) => void) => void;
  getGraphData: (
    vid: string,
    strip: string,
    zone: string,
    algo: AlgoType,
    setState: (value: GraphPoint[]) => void,
    limit?: number
  ) => void;
  getStripGraphData: (strip: StripItem, setState: (value: ActivityHistory) => void) => void;
  getBatteries: (cUnits: CUnitItem[], setState: (value: Battery[]) => void) => void;
  getStats: (cUnits: CUnitItem[], setState: (value: (Stat | { state: State })[]) => void) => void;

  // Mutations
  setOverviewImage: (item: Object, fileKey: string, relation: string) => Promise<Object>;
  setLocationImage: (item: CUnitItem, fileKey: string) => Promise<CUnitItem>;
  setCrackImage: (item: StripItem, fileKey: string) => Promise<StripItem>;
  setCULocation: (item: CU, posX: number, posY: number) => Promise<CU>;
}

const APIContext = createContext<IAPIContext>({} as IAPIContext);

const APIProvider: FC = ({ children }) => {
  const { user, route } = useAuthenticator((context) => [context.user]);
  const [customers, setCustomers] = useState<CustomerItem[]>([]);
  const [dashboard, setDashboard] = useState<Dashboard>();

  // STATE METHODS
  const getDashboardConfig = () => api.fetchDashboardConfig().then((dashboard) => setDashboard(dashboard));

  // State Change: setCustomers -> CustomerItem[]
  const getCustomerItems = () =>
    api
      .fetchCustomerData({ sub: user.attributes.sub })
      .then((customers) =>
        Promise.all(customers.map(({ entId }) => api.fetchObjData({ entId }))).then((objs) =>
          setCustomers(customers.map((bu, idx) => ({ item: bu, nodes: objs[idx] })))
        )
      );

  // State Change: external setState -> CUnitItem[]
  const getOverviewData = (object: Object, setState: (value: CUnitItem[]) => void) =>
    api.fetchCUnitData({ entId: object.entId, objId: object.id }).then(async (cUnits) => {
      const unitsWithStripsAndZones = await Promise.all(
        cUnits.map((cu) =>
          api.fetchStripData({ entId: cu.entId, objId: cu.objId, cuId: cu.id }).then((strips) =>
            Promise.all(
              strips.map((strip) =>
                api
                  .fetchZoneData({
                    entId: strip.entId,
                    objId: strip.objId,
                    cuId: strip.cuId,
                    stripId: strip.id
                  })
                  .then((zones) => ({ strip, zones }))
              )
            ).then((stripZones) => ({
              item: cu,
              nodes: stripZones.map(({ strip: strip_2, zones: zones_1 }) => ({
                item: strip_2,
                nodes: zones_1
              }))
            }))
          )
        )
      );
      return setState(unitsWithStripsAndZones);
    });

  // State Change: external setState -> Report[]
  const getReportData = (cUnits: CUnitItem[], algo: AlgoType, setState: (value: Report[]) => void) =>
    api
      .fetchReportData({
        vids: cUnits.map((cUnit) => cUnit.item.id),
        algo
      })
      .then((reports) => setState(reports));

  // State Change: external setState -> GraphPoint[]
  const getGraphData = (
    vid: string,
    strip: string,
    zone: string,
    algo: AlgoType,
    setState: (value: GraphPoint[]) => void,
    limit?: number
  ) => api.fetchGraphData({ vid, strip, zone, algo, limit }).then((result) => setState(result.reverse()));

  // State Change: external setState -> ActivityHistory[]
  const getStripGraphData = async (strip: StripItem, setState: (value: ActivityHistory) => void) => {
    const results = await Promise.all(
      strip.nodes.map((zone: Zone) =>
        api.fetchGraphData({
          vid: zone.cuId,
          strip: zone.stripId,
          zone: zone.id,
          algo: strip.item.config.algoType,
          limit: 20000
        })
      )
    );

    let from: Date | null = null;
    let to: Date | null = null;

    const activities = results.map((result, idx) => {
      const history = result.reduce((agg: ActivityPoint[], { datetime, value }) => {
        const currElementDate = new Date(datetime);
        const currActivity = getActivity(value, strip.nodes[idx].threshold, customers.find(cust => cust.item.entId === strip.item.entId).item.applyWatermelon, strip.item.state);

        if (!from || currElementDate < from) {
          from = currElementDate;
        }

        if (!to || currElementDate > to) {
          to = currElementDate;
        }

        const previousElement = agg[agg.length - 1];
        if (
          !previousElement ||
          currElementDate.getDate() !== previousElement.datetime.getDate() ||
          currElementDate.getMonth() !== previousElement.datetime.getMonth() ||
          currElementDate.getFullYear() !== previousElement.datetime.getFullYear()
        ) {
          agg.push({
            datetime: currElementDate,
            activity: currActivity
          });
        } else {
          previousElement.activity = activityReducer([previousElement.activity, currActivity]);
        }

        return agg;
      }, []);

      return { zone: strip.nodes[idx], history };
    });

    setState({
      activities,
      from: new Date(from.getFullYear(), from.getMonth(), from.getDate(), 23, 59, 59),
      to: new Date(to.getFullYear(), to.getMonth(), to.getDate(), 23, 59, 59)
    });
  };

  // State Change: external setState -> Battery[]
  const getBatteries = (cUnits: CUnitItem[], setState: (value: Battery[]) => void) =>
    api
      .fetchBatteryData({ vids: cUnits.map((cUnit) => cUnit.item.id) })
      .then((batteries) => setState(batteries));

  // State Change: external setState -> Stat | { state: State })[]
  const getStats = (cUnits: CUnitItem[], setState: (value: (Stat | { state: State })[]) => void) => {
    const cUnitsActivityMapping = cUnits.reduce((agg, curr) => {
      agg.set(curr.item.id, curr.item.state);
      return agg;
    }, new Map<CU['id'], CU['state']>());
    api
      .fetchStatsData({ vids: Array.from(cUnitsActivityMapping.keys()) })
      .then((stats) => setState(stats.map((stat) => ({ ...stat, state: cUnitsActivityMapping[stat.vid] }))));
  };

  // Update Overview fileKey
  const setOverviewImage = async (object: Object, fileKey: string, relation: string): Promise<Object> => {
    const images: Image[] = object.img;
    const image = images.find((i) => i.id.toLowerCase() === relation.toLowerCase());

    if (image.fileKey !== fileKey) {
      await api.updateOverviewImage({
        input: {
          entId: object.entId,
          objId: object.id,
          fileKey,
          idx: images.indexOf(image)
        }
      });
      image.fileKey = fileKey;
    }

    return object;
  };

  // Update Location fileKey
  const setLocationImage = async (item: CUnitItem, fileKey: string): Promise<CUnitItem> => {
    const cUnit = item.item;
    const image = cUnit.img;

    if (image.fileKey !== fileKey) {
      await api.updateLocationImage({
        input: { entId: cUnit.entId, objId: cUnit.objId, cuId: cUnit.id, fileKey }
      });
      image.fileKey = fileKey;
    }

    return item;
  };

  // Update Crack fileKey
  const setCrackImage = async (item: StripItem, fileKey: string): Promise<StripItem> => {
    const strip = item.item;
    const image = strip.img;

    if (image.fileKey !== fileKey) {
      await api.updateCrackImage({
        input: {
          entId: strip.entId,
          objId: strip.objId,
          cuId: strip.cuId,
          stripId: strip.id,
          fileKey
        }
      });
      image.fileKey = fileKey;
    }

    return item;
  };

  const setCULocation = async (item: CU, left: number, top: number): Promise<CU> => {
    const cUnit = item;

    await api.updateCULocation({
      input: {
        entId: cUnit.entId,
        objId: cUnit.objId,
        cuId: cUnit.id,
        posX: left,
        posY: top
      }
    });

    cUnit.posX = left;
    cUnit.posY = top;

    return item;
  };

  // Pull <BU, Object[]> part of metadata, on page load for authenticated user
  useEffect(() => {
    if (route !== 'authenticated') return setCustomers([]);
    getDashboardConfig();
    getCustomerItems();
  }, [route]);

  return (
    <APIContext.Provider
      value={{
        customers,
        dashboard,

        getOverviewData,
        getReportData,
        getGraphData,
        getStripGraphData,
        getBatteries,
        getStats,

        setOverviewImage,
        setLocationImage,
        setCrackImage,
        setCULocation
      }}
    >
      {children}
    </APIContext.Provider>
  );
};
export const useAPI = (): IAPIContext => useContext(APIContext);

export default APIProvider;
