import NotificationIcon from 'src/components/NotificationIcon';
import notifications from 'src/content/notifications.json';
import { useTenant, Tenant } from 'src/hooks/TenantHook';

const HeaderActionsBox = () => {
  const { [Tenant.ADMIN]: isAdmin } = useTenant();
  const firstThreeNotifications = notifications.slice(0, 3);

  return isAdmin ? <NotificationIcon notifications={firstThreeNotifications} badge={notifications.length} /> : <></>;
};

export default HeaderActionsBox;
