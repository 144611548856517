import { createContext, FC, MutableRefObject, useContext, useEffect, useRef, useState } from 'react';

import { CUnitItem, ReportStrip, ReportZone, StripItem } from 'src/models';
import { Customer, Object, Zone } from 'src/models/API';

import { useData } from './DataContext';
import { useS3Image } from 'src/hooks/ImageLoaderHook';
import * as core from 'echarts/core';

export enum Tab {
  OVERVIEW,
  REPORT,
  DETAILS
}

export type OverviewImageData = {
  url: string;
  fileName: string;
  title: string;
  component;
};

export type Selection = {
  customer: Customer;
  object: Object;
  cUnit?: CUnitItem;
  strip?: StripItem;
  zone?: Zone;
};

type Filter = {
  cUnitId?: string | null;
};

interface IDashboardContext {
  selection: Selection;
  selectCUnit: (cUnit: CUnitItem) => void;
  selectStrip: (strip: StripItem) => void;
  selectZone: (zone: Zone) => void;
  selectDetails: (report: ReportStrip) => void;
  selectSegment: (report: ReportZone) => void;
  selectDefault: () => void;
  removeSelection: () => void;

  filtered: Filter;
  filterCUnit: (cUnitId: string) => void;
  removeCUnitFilter: () => void;

  overviewImages: OverviewImageData[];
  loadOverviewImages: () => void;
  filteredOverviewImages: OverviewImageData[];
  filterOverviewImages: () => void;

  activeTab: number;
  switchTo: (tab: Tab) => void;

  graphChartRef: MutableRefObject<core.ECharts>;
}

const DashboardContext = createContext<IDashboardContext>({} as IDashboardContext);

const DashboardProvider: FC = ({ children }) => {
  const graphChartRef = useRef<core.ECharts | null>(null);
  const { cUnits, customer, object } = useData();
  const urlLoader = useS3Image();

  const [overviewImages, setOverviewImages] = useState<OverviewImageData[]>([]);
  const [filteredOverviewImages, setFilteredOverviewImages] = useState<OverviewImageData[]>([]);
  // Selection State & Selectors
  const [selection, __setSelect] = useState<Selection>({ customer, object });
  const selectCUnit = (cUnit: CUnitItem) => __setSelect({ customer, object, cUnit });
  const selectStrip = (strip: StripItem) => __setSelect({ customer, object, cUnit: selection.cUnit, strip });
  const selectZone = (zone: Zone) =>
    __setSelect({ customer, object, cUnit: selection.cUnit, strip: selection.strip, zone });
  const selectDetails = (report: ReportStrip) => {
    const cUnit = cUnits.find(({ item }) => item.id === report.cuId);
    const strip = cUnit.nodes.find(({ item }) => item.id === report.id);
    __setSelect({ customer, object, cUnit, strip, zone: strip.nodes[0] });
  };
  const selectSegment = (report: ReportZone) => {
    const cUnit = cUnits.find(({ item }) => item.id === report.cuId);
    const strip = cUnit.nodes.find(({ item }) => item.id === report.stripId);
    const zone = strip.nodes.find((item) => item.id === report.id);
    __setSelect({ customer, object, cUnit, strip, zone: zone });
  };
  const selectDefault = () => {
    const cUnit = cUnits[0];
    const strip = cUnit.nodes[0];
    const zone = strip.nodes[0];
    __setSelect({ customer, object, cUnit, strip, zone });
  };
  const removeSelection = () => __setSelect({ customer, object });

  // Tab State & Switchers
  const [activeTab, __setActiveTab] = useState<Tab>(Tab.OVERVIEW);
  const switchTo = (tab: Tab) => __setActiveTab(tab);

  // Filter State & Filters
  const [filtered, __setFiltered] = useState<Filter>({});
  const filterCUnit = (cUnitId: string) => __setFiltered({ ...filtered, cUnitId });
  const removeCUnitFilter = () => __setFiltered({ ...filtered, cUnitId: null });

  const loadOverviewImages = () => {
    if (overviewImages.length) return; // don't fetch if already loaded

    Promise.all(selection.object.img.map((img) => urlLoader('overview', img.fileKey))).then((urls) => {
      setOverviewImages(
        urls.map((url, idx) => ({
          url,
          fileName: selection.object.img[idx].fileKey,
          title: selection.object.img[idx].id.toUpperCase(),
          component: <img src={url} alt={selection.object.img[idx].id} />
        }))
      );
    });
  };

  useEffect(() => {
    loadOverviewImages();
  }, [cUnits]);

  const filterOverviewImages = () => {
    if (!overviewImages.length) loadOverviewImages();
    if (selection.cUnit) {
      setFilteredOverviewImages(
        overviewImages.filter((img) => img.title === selection.cUnit.item.img.id.toUpperCase())
      );
    }
  };

  return (
    <DashboardContext.Provider
      value={{
        selection,
        selectCUnit,
        selectStrip,
        selectZone,
        selectDetails,
        selectSegment,
        selectDefault,
        removeSelection,

        filtered,
        filterCUnit,
        removeCUnitFilter,

        overviewImages,
        loadOverviewImages,
        filteredOverviewImages,
        filterOverviewImages,

        activeTab,
        switchTo,
        graphChartRef
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboard = (): IDashboardContext => useContext(DashboardContext);

export default DashboardProvider;
