import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom'
import App from 'src/App';
import * as serviceWorker from 'src/serviceWorker';

import awsconfig from './aws-exports';
import { Amplify, Storage } from 'aws-amplify';

import './i18n';
import './utils/stringExtensions';

Amplify.configure(awsconfig);
Storage.configure({ level: 'private' });

const root = createRoot(document.getElementById('root'));
root.render(
  <HelmetProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </HelmetProvider>
);

serviceWorker.unregister();
