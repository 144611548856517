import { useAuthenticator } from '@aws-amplify/ui-react';
import { FC, createContext, useContext, useEffect, useState } from 'react';

import { Activity, CUnitItem, ReportData, activityReducer, getActivity, getObjectState } from 'src/models';
import { Customer, Object, Report, State } from 'src/models/API';
import { useAPI } from './APIContext';

interface IDataContext {
  customer: Customer;
  object: Object;
  cUnits: CUnitItem[];
  report: ReportData;
}

export interface IDataProviderProps {
  customer: Customer;
  object: Object;
}

const DataContext = createContext<IDataContext>({} as IDataContext);

const DataProvider: FC<IDataProviderProps> = ({ customer, object, children }) => {
  const { route } = useAuthenticator((context) => [context.route]);
  const { customers, getOverviewData, getReportData } = useAPI();

  // Data States
  const [cUnits, setCUnits] = useState<CUnitItem[]>([]); // The CUnits and Strip data for the object
  const [report, __setReport] = useState<ReportData>();

  const setReports = (reports: Report[]) => {
    const reportCUnit: ReportData['cUnit'] = [];
    const reportStrip: ReportData['strip'] = [];
    const reportZone: ReportData['zone'] = [];
    const range = {
      from: new Date('9999-12-31'),
      to: new Date('0000-01-01')
    };

    const activities: Activity[] = [];

    for (const report of reports) {
      const cUnit = cUnits.find((cUnit) => cUnit.item.id === report.vid);
      if (!cUnit) continue;

      const strip = cUnit.nodes.find(({ item }) => item.id === report.strip);
      if (!strip) continue;

      const zone = strip.nodes.find(({ id }) => id === report.zone);
      if (!zone) continue;

      const reportActivity = getActivity(report.value, strip.item.threshold, customer.applyWatermelon, getObjectState(object, cUnit, strip), report.datetime);

      const reportDatetime = new Date(report.datetime);

      // Object Aggregation
      activities.push(reportActivity);

      // CU Aggregation
      const prevCU = reportCUnit.find(({ vid }) => vid === report.vid);
      if (!prevCU) {
        reportCUnit.push({
          ...cUnit.item,
          ...report,
          activity: reportActivity
        });
      } else {
        prevCU.value = Math.max(prevCU.value, report.value);
        prevCU.activity = activityReducer([prevCU.activity, reportActivity], getObjectState(object, cUnit, strip));
      }

      // Strip Aggregation
      const prevStrip = reportStrip.find(({ vid, strip }) => vid === report.vid && strip === report.strip);
      if (!prevStrip) {
        reportStrip.push({
          ...strip.item,
          ...report,
          activity: reportActivity,
          connectivity: [[report.daily, report.weekly]]
        });

        if (reportDatetime <= range.from) {
          range.from = reportDatetime;
        }
      } else {
        prevStrip.value = Math.max(prevStrip.value, report.value);
        prevStrip.activity = activityReducer([prevStrip.activity, reportActivity]);
        prevStrip.connectivity.push([report.daily, report.weekly]);

        if (reportDatetime >= range.to) {
          range.to = reportDatetime;
        }
      }

      // Zone Aggregation
      reportZone.push({
        ...zone,
        ...report,
        activity: reportActivity
      });
    }

    const activityStats = reportStrip.reduce(
      (agg, rprt) => {
        agg[rprt.activity]++;
        return agg;
      },
      {
        no: 0,
        moderate: 0,
        significant: 0,
        off: 0,
        pending: 0
      }
    );

    __setReport({
      cUnit: reportCUnit,
      strip: reportStrip,
      zone: reportZone,
      activity: activityReducer(activities, object.state),
      range,
      stats: {
        activity: activityStats,
        total: {
          cUnit: reportCUnit.length,
          strip: reportStrip.length
        }
      }
    });
  };

  // Load Overview data for object
  useEffect(() => {
    if (!customers.length || route !== 'authenticated') return () => null;
    getOverviewData(object, setCUnits);
  }, [customers, route]);

  // Load ReportData for object
  useEffect(() => {
    if (!cUnits.length || route !== 'authenticated') return () => null;
    getReportData(cUnits, cUnits[0].nodes[0].item.config.algoType, setReports);
  }, [cUnits, route]);

  return (
    <DataContext.Provider
      value={{
        customer,
        object,
        cUnits,
        report
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useData = (): IDataContext => useContext(DataContext);

export default DataProvider;
