import { useAuthenticator } from '@aws-amplify/ui-react';
import { useEffect, useState } from 'react';

export enum Tenant {
  ADMIN = 'admin',
  STAFF = 'staff',
  ARCHIVIST = 'archivist',
  CUSTOMER = 'customer',
  END = 'end_customer',
  BASIC = 'basic'
}

interface TenantStatus {
  [Tenant.ADMIN]: boolean;
  [Tenant.STAFF]: boolean;
  [Tenant.ARCHIVIST]: boolean,
  [Tenant.CUSTOMER]: boolean;
  [Tenant.END]: boolean;
  [Tenant.BASIC]: boolean;
}

export function useTenant() {
  const { user } = useAuthenticator((context) => [context.user]);
  const [tenantStatus, setTenantStatus] = useState<TenantStatus>({
    [Tenant.ADMIN]: false,
    [Tenant.STAFF]: false,
    [Tenant.ARCHIVIST]: false,
    [Tenant.CUSTOMER]: false,
    [Tenant.END]: false,
    [Tenant.BASIC]: false
  });

  useEffect(() => {
    const groups: string[] = user.getSignInUserSession().getAccessToken().payload['cognito:groups'] ?? [];

    const isAdmin = groups.includes(Tenant.ADMIN);
    const isStaff = isAdmin || groups.includes(Tenant.STAFF);
    const isArchivist = isStaff || groups.includes(Tenant.ARCHIVIST);
    const isCustomer = isArchivist || groups.includes(Tenant.CUSTOMER);
    const isEnd = isCustomer || groups.includes(Tenant.END);
    const isBasic = isEnd || groups.includes(Tenant.BASIC) || true;

    setTenantStatus({
      [Tenant.ADMIN]: isAdmin,
      [Tenant.STAFF]: isStaff,
      [Tenant.ARCHIVIST]: isArchivist,
      [Tenant.CUSTOMER]: isCustomer,
      [Tenant.END]: isEnd,
      [Tenant.BASIC]: isBasic
    });

  }, [user]);

  return tenantStatus;
}
