import { withAuthenticator } from '@aws-amplify/ui-react';
import { CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useRoutes } from 'react-router-dom';

import TermsOfService from './components/TermsOfService';
import APIProvider from './contexts/APIContext';
import SidebarProvider from './contexts/SidebarContext';
import router from './router';
import ThemeProvider from './theme/ThemeProvider';

import '@aws-amplify/ui-react/styles.css';
import 'src/theme/awsAmplifyStyles.css'; // NOTE: Overwrites default AWS Amplify login form styles!!!

const App = () => {
  const content = useRoutes(router);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <APIProvider>
          <TermsOfService>
            <SidebarProvider>
              <CssBaseline />
              <DndProvider backend={HTML5Backend}>{content}</DndProvider>
            </SidebarProvider>
          </TermsOfService>
        </APIProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default withAuthenticator(App, { hideSignUp: true });
