import { Box, CircularProgress, useTheme } from '@mui/material';
import { FC, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';

import Footer from 'src/components/Footer';

import Header from './Header';
import Sidebar from './Sidebar';
import { useAPI } from 'src/contexts/APIContext';
import StatusMaintenance from 'src/content/pages/Status/Maintenance';

interface SidebarLayoutProps {
  children?: ReactNode;
}

const SidebarLayout: FC<SidebarLayoutProps> = () => {
  const theme = useTheme();
  const { dashboard } = useAPI();

  return dashboard ? (
    dashboard.isMaintenance ? (
      <StatusMaintenance />
    ) : (
      <>
        <Box height="100%" flex={1}>
          <Header />
          <Sidebar />
          <Box
            sx={{
              position: 'relative',
              zIndex: 5,
              display: 'block',
              pt: `${theme.header.height}`,
              [theme.breakpoints.up('md')]: {
                ml: `${theme.sidebar.width}`
              }
            }}
          >
            <Box display="block" minHeight={`calc(100vh - ${theme.header.height} - ${theme.footer.height})`}>
              <Outlet />
            </Box>
            <Footer />
          </Box>
        </Box>
      </>
    )
  ) : (
    <CircularProgress />
  );
};

export default SidebarLayout;
