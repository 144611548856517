import {
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Popover,
  Tooltip,
  Typography,
  alpha
} from '@mui/material';
import { formatDistance, subDays } from 'date-fns';
import { useRef, useState } from 'react';

import { Notification } from 'src/models/Notification';
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router';

const NotificationsBadge = styled(Badge)(
  ({ theme }) => `
      .MuiBadge-badge {
          background-color: ${alpha(theme.palette.error.main, 0.1)};
          color: ${theme.palette.error.main};
          min-width: 16px; 
          height: 16px;
          padding: 0;
  
          &::after {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              border-radius: 50%;
              box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
              content: "";
          }
      }
  `
);

interface INotificationIconProps {
  notifications: Notification[];
  badge: number;
}

const NotificationIcon = (props: INotificationIconProps) => {
  const { notifications, badge } = props;

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <Box display="flex" height="50%" alignItems="center">
      <Tooltip arrow title="Notifications">
        <IconButton size="small" color="primary" ref={ref} onClick={handleOpen}>
          <NotificationsBadge
            badgeContent={badge}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <NotificationsRoundedIcon />
          </NotificationsBadge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <List disablePadding>
          <ListItem>
            <ListItemText primary="Notifications" />
          </ListItem>
          <Divider />
          {notifications.map((notification) => (
            <ListItem key={notification.id}>
              <ListItemText
                primary={
                  <Box display="flex" justifyContent="space-between">
                    <Typography>Alert</Typography>
                    <Typography>
                      {formatDistance(subDays(new Date(), 3), new Date(), { addSuffix: true })}
                    </Typography>
                  </Box>
                }
                secondary={`${notification.message} - Status: ${notification.status}`}
              />
            </ListItem>
          ))}
          <ListItem sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button size="small" variant="text" color="primary" onClick={() => navigate('/notifications')}>
              ... ({badge})
            </Button>
          </ListItem>
        </List>
      </Popover>
    </Box>
  );
};

export default NotificationIcon;
