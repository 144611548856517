import { styled, useTheme } from '@mui/material';

import { Link } from 'react-router-dom';

const LogoWrapper = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: theme.sidebar.width
}));

const SvgComponent = (props: any) => (
  <svg
    id="VillariLogoSVG"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 1437 645.5"
    style={{
      enableBackground: 'new 0 0 1437 645.5'
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {'.st2{fill:none;stroke:#fff;stroke-width:4;stroke-miterlimit:10}.st3{fill:#fff}.st4{fill:#025878}'}
    </style>

    {/* Small line */}
    <path
      d="M 78.4 278.5 h 400 c 104.9 0, 120 -100, 240 -100 s 135 100, 240 100 h 400"
      style={{
        fill: 'none',
        stroke: '#025878',
        strokeWidth: 4,
        strokeMiterlimit: 10,
        strokeDasharray: 2000,
        strokeDashoffset: 2000
      }}
    >
      <animate
        attributeName="stroke-dashoffset"
        dur="15s"
        repeatCount="indefinite"
        values="2000; 0; 2000;"
        keyTimes="0; 0.2; 1"
        calcMode="spline"
        keySplines="0.5 0 0.5 1; 0.5 0 0.5 1"
      />
    </path>

    {/* Big line */}
    <path
      d="M 78.4 314.5 h 400 c 200 0, 200 -240, 220 -240 s 20 480, 40 480, 20 -240, 220 -240 h 400"
      style={{
        fill: 'none',
        stroke: '#025878',
        strokeWidth: 4,
        strokeMiterlimit: 10,
        strokeDasharray: 2000,
        strokeDashoffset: 2000
      }}
    >
      <animate
        attributeName="stroke-dashoffset"
        dur="15s"
        repeatCount="indefinite"
        values="0; 2000; 0;"
        keyTimes="0; 0.8; 1"
        calcMode="spline"
        keySplines="0.5 0 0.5 1; 0.5 0 0.5 1"
      />
    </path>

    {/* VILLARI */}
    <path
      d="m118.8 201.2 97.4 204.2h23l90.5-204.2h-31.6l-61.7 139.1-66.5-139.1h-51.1zm238 201.3h49.3V201.2h-49.3v201.3zm99.7 0h152.1v-20.6H505.9V201.2h-49.4v201.3zm183.9 0h152.1v-20.6H689.9V201.2h-49.4v201.3h-.1zm258.9-205.4-98.1 205.4h33.5l26.4-54.9h78.2l25.2 54.9h50.5l-94.4-205.4h-21.3zm1.9 66.8 29.8 65h-60.6l30.8-65zm141.9 138.6h49.4v-91h8.8l67.5 91h53.7l-71.7-96.3c6.8-2.2 13.1-4.9 18.8-8.2 5.7-3.3 10.5-7.1 14.5-11.4s7.1-9 9.4-14.1c2.2-5.1 3.3-10.5 3.3-16.3 0-7.9-1.6-15.2-4.9-22-3.3-6.7-8.1-12.5-14.6-17.4-6.5-4.9-14.6-8.7-24.3-11.4-9.7-2.7-21.2-4.1-34.2-4.1h-75.7v201.2zm49.5-181.7h20c4.8 0 9.2.9 13.2 2.7 4.1 1.8 7.5 4.3 10.4 7.5 2.9 3.2 5.1 7.1 6.8 11.5s2.4 9.4 2.4 14.7c0 5.7-1.1 10.9-3.2 15.4-2.1 4.6-5.1 8.4-8.8 11.5-3.8 3.1-8.2 5.5-13.4 7.2-5.1 1.7-10.7 2.5-16.8 2.5h-10.7v-73h.1zm148.9 181.7h49.3V201.2h-49.3v201.3z"
      style={{
        fill: '#005677'
      }}
    />
  </svg>
);

const Logo = () => {
  const theme = useTheme();
  return (
      <LogoWrapper to="/" sx={{ flexGrow: 0, display: { xs: 'none', sm: 'flex' } }}>
        <SvgComponent height={theme.header.height} />
      </LogoWrapper>
  );
};

export default Logo;
