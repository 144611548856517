/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../models/API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const setOverviewImage = /* GraphQL */ `mutation SetOverviewImage($input: OverviewImageInput!) {
  setOverviewImage(input: $input)
}
` as GeneratedMutation<
  APITypes.SetOverviewImageMutationVariables,
  APITypes.SetOverviewImageMutation
>;
export const setLocationImage = /* GraphQL */ `mutation SetLocationImage($input: LocationImageInput!) {
  setLocationImage(input: $input)
}
` as GeneratedMutation<
  APITypes.SetLocationImageMutationVariables,
  APITypes.SetLocationImageMutation
>;
export const setCrackImage = /* GraphQL */ `mutation SetCrackImage($input: CrackImageInput!) {
  setCrackImage(input: $input)
}
` as GeneratedMutation<
  APITypes.SetCrackImageMutationVariables,
  APITypes.SetCrackImageMutation
>;
export const setCULocation = /* GraphQL */ `mutation SetCULocation($input: CULocationInput!) {
  setCULocation(input: $input)
}
` as GeneratedMutation<
  APITypes.SetCULocationMutationVariables,
  APITypes.SetCULocationMutation
>;
