import { AppBar, Box, Container, Grid, IconButton, Toolbar, useTheme } from '@mui/material';

import HeaderActionsBox from './HeaderActionsBox';
import HeaderUserbox from './HeaderUserbox';
import Logo from 'src/components/Logo';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { useSidebar } from 'src/contexts/SidebarContext';

function Header() {
  const { toggleSidebar } = useSidebar();
  const theme = useTheme();

  return (
    <AppBar position="fixed" elevation={1} sx={{ boxShadow: theme.colors.shadows.primary, zIndex: theme.zIndex.drawer + 1 }}>
      <Container maxWidth={false} sx={{ paddingLeft: { md: 0 } }}>
        <Toolbar disableGutters sx={{ height: theme.header.height, justifyContent: 'space-between'}}>
          <Box sx={{ display: { sm: 'flex', md: 'none' }, minWidth: {sm: 125, xs: 0} }}>
            <IconButton color="primary" onClick={toggleSidebar}>
              <MenuTwoToneIcon />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: 'none', sm: 'flex' }, justifyContent: { sm: 'center', md: 'flex-start' } }}>
            <Logo />
          </Box>
          <Box my={1} display="flex" sx={{ justifyContent: "flex-end", minWidth: 125, gap: 1, alignItems: 'center'}}>
            <HeaderActionsBox />
            <HeaderUserbox />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Header;
