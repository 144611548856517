import { Box, Link, Typography, styled, Divider, useTheme, Tooltip } from '@mui/material';

import pk from '../../package.json';

const FooterWrapper = styled(Box)(({ theme }) => ({
  height: theme.footer.height,
  maxHeight: theme.footer.height
}));

function Footer() {
  const theme = useTheme();
  return (
    <FooterWrapper
      sx={{
        paddingTop: {
          xs: theme.spacing(1),
          mb: theme.spacing(2)
        }
      }}
    >
      <Divider
        sx={{
          mx: theme.spacing(2),
          background: theme.palette.primary.main
        }}
      />
      <Box
        pt={1}
        px={4}
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Box>
          <Tooltip title={`Version ${pk.version}`} arrow placement="right">
            <Typography variant="body2">
              &copy; {new Date().getFullYear()} - {pk.title}
            </Typography>
          </Tooltip>
        </Box>
        <Typography variant="body2">
          Created by{' '}
          <Link href="https://villari.nl" target="_blank" rel="noopener noreferrer">
            Villari
          </Link>
        </Typography>
      </Box>
    </FooterWrapper>
  );
}

export default Footer;
