import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import Loader from 'src/components/LazyLoader';
import BaseLayout from 'src/layouts/BaseLayout';
import SidebarLayout from 'src/layouts/SidebarLayout';
import Download from './content/pages/Download';

// Pages
const Home = Loader(() => import('src/content/pages/Home'));
const Dashboard = Loader(() => import('src/content/pages/Dashboard'));
const Account = Loader(() => import('src/content/pages/Account'));
const Notifications = Loader(() => import('src/content/pages/Notifications'));
const FAQ = Loader(() => import('src/content/pages/FAQ'));

// Status
const Status404 = Loader(() => import('src/content/pages/Status/Status404'));
const Status500 = Loader(() => import('src/content/pages/Status/Status500'));
const StatusComingSoon = Loader(() => import('src/content/pages/Status/ComingSoon'));
const StatusMaintenance = Loader(() => import('src/content/pages/Status/Maintenance'));

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="home" replace />
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: '/home',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Home />
      }
    ]
  },
  {
    path: '/dashboard',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Dashboard />
      }
    ]
  },
  {
    path: '/notifications',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Notifications />
      }
    ]
  },
  {
    path: '/profile',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="settings" replace />
      },
      {
        path: 'settings',
        element: <Account />
      }
    ]
  },
  {
    path: '/admin',
    element: <BaseLayout />,
    children: [
      {
        path: '',
        element: <StatusComingSoon />
      }
    ]
  },
  {
    path: '/faq',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <FAQ />
      }
    ]
  },
  {
    path: '/download',
    element: <SidebarLayout/>,
    children: [
      {
        path: '',
        element: <Download/>
      }
    ]
  }
];

export default routes;
