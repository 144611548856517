import { graphqlOperation, API } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api-graphql';

import * as api from 'src/models/API';

import * as M from './mutations';
import * as Q from './queries';

/**
 * Generic GraphQL async operation handler.
 * @param { M } module - GraphQL Query or Mutation
 * @param { string } oper - GraphQL operation
 * @param { V } vars - GraphQL operation variables
 * @returns { R } data OR undefined
 */
async function operation<M, V, R>(module: M, oper: string, vars?: V): Promise<R> {
  try {
    const result = (await API.graphql(graphqlOperation(module[oper], vars))) as GraphQLResult;
    return result.data[oper] as R;
  } catch (err) {
    console.error(`Operation failed: ${oper} with ${vars}`, err);
    return [] as unknown as R;
  }
}

// QUERIES
/* prettier-ignore */ export const fetchDashboardConfig = async (): Promise<api.Dashboard> => operation(Q, 'getDashboardConfig');
/* prettier-ignore */ export const fetchCustomerData = async (params: api.GetCustomersQueryVariables): Promise<api.Customer[]> => operation(Q, 'getCustomers', params);
/* prettier-ignore */ export const fetchObjData = async (params: api.GetObjectsQueryVariables): Promise<api.Object[]> => operation(Q, 'getObjects', params);
/* prettier-ignore */ export const fetchCUnitData = async (params: api.GetCUsQueryVariables): Promise<api.CU[]> => operation(Q, 'getCUs', params);
/* prettier-ignore */ export const fetchStripData = async (params: api.GetStripsQueryVariables): Promise<api.Strip[]> => operation(Q, 'getStrips', params);
/* prettier-ignore */ export const fetchZoneData = async (params: api.GetZonesQueryVariables): Promise<api.Zone[]> => operation(Q, 'getZones', params);
/* prettier-ignore */ export const fetchReportData = async (params: api.GetReportsQueryVariables): Promise<api.Report[]> => operation(Q, 'getReports', params);
/* prettier-ignore */ export const fetchGraphData = async (params: api.GetGraphPointsQueryVariables): Promise<api.GraphPoint[]> => operation(Q, 'getGraphPoints', params);
/* prettier-ignore */ export const fetchBatteryData = async (params: api.GetBatteriesQueryVariables): Promise<api.Battery[]> => operation(Q, 'getBatteries', params);
/* prettier-ignore */ export const fetchStatsData = async (params: api.GetStatsQueryVariables): Promise<api.Stat[]> => operation(Q, 'getStats', params);

// MUTATIONS
/* prettier-ignore */ export const updateOverviewImage = async (params: api.SetOverviewImageMutationVariables) => operation(M, 'setOverviewImage', params);
/* prettier-ignore */ export const updateLocationImage = async (params: api.SetLocationImageMutationVariables) => operation(M, 'setLocationImage', params);
/* prettier-ignore */ export const updateCrackImage = async (params: api.SetCrackImageMutationVariables) => operation(M, 'setCrackImage', params);
/* prettier-ignore */ export const updateCULocation = async (params: api.SetCULocationMutationVariables) => operation(M, 'setCULocation', params);
