import { Storage } from 'aws-amplify';

import { ImageCategory } from 'src/models/ImageCategory';

export function useS3Image() {
  const __getFromStorage = async (fileKey: string, level: 'public' | 'private', download: boolean): Promise<string> => {
    if (!fileKey) return;

    try {
      return await Storage.get(fileKey, { level, download });
    } catch (err) {
      console.error(`Failed to get img: ${fileKey}`, err);
    }
  };

  const urlLoader = async (category: ImageCategory, fileKey: string, download = false): Promise<string> =>
    __getFromStorage(fileKey, ['avatar'].includes(category) ? 'private' : 'public', download);

  return urlLoader;
}
