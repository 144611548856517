import { Box, Typography, styled, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem, treeItemClasses, TreeItemProps } from '@mui/x-tree-view/TreeItem';
import CustomerIcon from '@mui/icons-material/CorporateFareRounded';
import HiddenIcon from '@mui/icons-material/LabelOutlined';
import ObjectIcon from '@mui/icons-material/LabelRounded';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import { useAPI } from 'src/contexts/APIContext';
import { State } from 'src/models/API';

type CustomerItemProps = TreeItemProps & {
  title: string;
  subtitle?: string;
};

type ObjectItemProps = CustomerItemProps & {
  state?: State;
};

const MenuTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  [`& .${treeItemClasses.content}`]: {
    '&.CustomerNode.Mui-selection': {
      backgroundColor: theme.palette.secondary.main,
      color: 'inherit'
    }
  }
}));

const CustomerItem = (props: CustomerItemProps) => {
  const { title, subtitle, sx, ...other } = props;

  return (
    <MenuTreeItemRoot
      label={
        <Box sx={{ display: 'flex', alignItems: 'center', ...sx }}>
          <Box component={CustomerIcon} color="inherit" sx={{ mr: 1 }} />
          <Box display="flex" flexDirection="column">
            <Typography variant="h6" fontWeight="bold">
              {title}
            </Typography>
            {subtitle && (
              <Typography variant="subtitle2" fontWeight="normal">
                {subtitle}
              </Typography>
            )}
          </Box>
        </Box>
      }
      {...other}
    />
  );
};

const ObjectItem = (props: ObjectItemProps) => {
  const { title, subtitle, state, sx, ...other } = props;
  const theme = useTheme();

  const [icon, color] = (() => {
    switch (state) {
      case State.ARCHIVED: return [ObjectIcon, theme.colors.activityStatus.archived];
      case State.DISABLED:
      case State.BROKEN:
      case State.LOOSE: return [ObjectIcon, theme.colors.grey[500]];
      case State.HIDDEN: return [HiddenIcon, 'inherit'];
      default: return [ObjectIcon, 'inherit'];
    }
  })();

  return (
    <MenuTreeItemRoot
      label={
        <Box sx={{ display: 'flex', alignItems: 'center', ...sx }}>
          <Box component={icon} color={color} sx={{ mr: 1 }} />
          <Box display="flex" flexDirection="column">
            <Typography variant="h6" fontWeight="bold">
              {title}
            </Typography>
            {subtitle && (
              <Typography variant="subtitle2" fontWeight="normal">
                {subtitle}
              </Typography>
            )}
          </Box>
        </Box>
      }
      {...other}
    />
  );
};

const SidebarMenuTree = () => {
  const { customers } = useAPI();
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <TreeView
      aria-label="customers tree"
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
      sx={{
        height: '100%',
        flexGrow: 1,
        maxWidth: theme.sidebar.width,
        overflowY: 'auto',
        paddingX: '12px'
      }}
    >
      {customers.sort((a, b) => a.item.custName + a.item.buName > b.item.custName + b.item.buName ? 1 : -1).map(({ item, nodes }) => {
        if (!nodes.length) return;
        return (
          <CustomerItem
            key={item.entId}
            nodeId={item.entId}
            title={item.custName}
            subtitle={item.buName}
          >
            {nodes.map((object) => (
              <ObjectItem
                key={`${object.entId}-${object.id}`}
                nodeId={`${object.entId}-${object.id}`}
                title={object.objName}
                state={object.state}
                onClick={() => navigate('/dashboard', { state: { customer: item, object } })}
              />
            ))}
          </CustomerItem>
        );
      })}
    </TreeView>
  );
};

export default SidebarMenuTree;
