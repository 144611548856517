import { Customer, Object } from 'src/models/API';

export * from './validators';

export function extractFilename(fileKey: string): string {
  return fileKey.substring(fileKey.indexOf('/') + 1, fileKey.indexOf('.'));
}

export function stringToColor(string: string): string {
  if (!string) return '#FFF';

  let hash = 0;

  /* eslint-disable no-bitwise */
  for (let i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (let i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function extractExtension(path: string): string {
  return path.split('.').pop();
}

export function timeZoneOffset(): string {
  const offset = new Date().getTimezoneOffset();
  return `${Intl.DateTimeFormat().resolvedOptions().timeZone} (UTC${offset > 0 ? '-' : '+'}${Math.abs(
    Math.floor(offset / 60)
  )
    .toString()
    .padStart(2, '0')}:${Math.abs(Math.floor(offset % 60))
      .toString()
      .padStart(2, '0')})`;
}

export function tocapitalizeCase(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getCustomerName(customer: Customer): string {
  return customer.buName ? `${customer.custName}: ${customer.buName}` : customer.custName;
}

export function getObjectName(object: Object): string {
  return `${object.objType.toString().toCapitalCase()}: ${object.objName}`;
}
