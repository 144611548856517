/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../models/API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getDashboardConfig = /* GraphQL */ `query GetDashboardConfig {
  getDashboardConfig {
    tosVersion
    isMaintenance
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDashboardConfigQueryVariables,
  APITypes.GetDashboardConfigQuery
>;
export const getCustomers = /* GraphQL */ `query GetCustomers($sub: String!) {
  getCustomers(sub: $sub) {
    entId
    custName
    buName
    applyWatermelon
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCustomersQueryVariables,
  APITypes.GetCustomersQuery
>;
export const getObjects = /* GraphQL */ `query GetObjects($entId: String!) {
  getObjects(entId: $entId) {
    id
    entId
    state
    group
    objName
    objType
    count
    img {
      id
      fileKey
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetObjectsQueryVariables,
  APITypes.GetObjectsQuery
>;
export const getCUs = /* GraphQL */ `query GetCUs($entId: String!, $objId: String!) {
  getCUs(entId: $entId, objId: $objId) {
    id
    objId
    entId
    state
    group
    count
    img {
      id
      fileKey
      __typename
    }
    posX
    posY
    installationDate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetCUsQueryVariables, APITypes.GetCUsQuery>;
export const getStrips = /* GraphQL */ `query GetStrips($entId: String!, $objId: String!, $cuId: String!) {
  getStrips(entId: $entId, objId: $objId, cuId: $cuId) {
    id
    cuId
    objId
    entId
    state
    config {
      avg
      beginDate
      algoType
      algoVersion
      lastDataPoint
      minB
      maxB
      __typename
    }
    threshold {
      lower
      upper
      min
      max
      anomaly
      alarm
      __typename
    }
    location
    stripType
    mountedType
    isReversed
    startAngle
    radius
    count
    img {
      id
      fileKey
      __typename
    }
    pipeImg {
      id
      fileKey
      __typename
    }
    installationDate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetStripsQueryVariables, APITypes.GetStripsQuery>;
export const getZones = /* GraphQL */ `query GetZones(
  $entId: String!
  $objId: String!
  $cuId: String!
  $stripId: String!
) {
  getZones(entId: $entId, objId: $objId, cuId: $cuId, stripId: $stripId) {
    id
    stripId
    cuId
    objId
    entId
    threshold {
      lower
      upper
      min
      max
      anomaly
      alarm
      __typename
    }
    posX
    posY
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetZonesQueryVariables, APITypes.GetZonesQuery>;
export const getGraphPoints = /* GraphQL */ `query GetGraphPoints(
  $vid: String!
  $strip: String!
  $zone: String!
  $algo: AlgoType!
  $limit: Int
) {
  getGraphPoints(
    vid: $vid
    strip: $strip
    zone: $zone
    algo: $algo
    limit: $limit
  ) {
    datetime
    value
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetGraphPointsQueryVariables,
  APITypes.GetGraphPointsQuery
>;
export const getReports = /* GraphQL */ `query GetReports($vids: [String!]!, $algo: AlgoType!) {
  getReports(vids: $vids, algo: $algo) {
    vid
    strip
    zone
    datetime
    value
    daily
    weekly
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetReportsQueryVariables,
  APITypes.GetReportsQuery
>;
export const getBatteries = /* GraphQL */ `query GetBatteries($vids: [String!]) {
  getBatteries(vids: $vids) {
    vid
    datetime
    value
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBatteriesQueryVariables,
  APITypes.GetBatteriesQuery
>;
export const getStats = /* GraphQL */ `query GetStats($vids: [String!]) {
  getStats(vids: $vids) {
    vid
    battery
    daily
    weekly
    __typename
  }
}
` as GeneratedQuery<APITypes.GetStatsQueryVariables, APITypes.GetStatsQuery>;
