import { Suspense, lazy, ComponentType } from 'react';
import { Box, CircularProgress } from '@mui/material';

const SuspenseLoader = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '60vh'
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress size={64} disableShrink thickness={3} />
    </Box>
  );
};

const LazyLoader = (factory: () => Promise<{default: ComponentType<any>}>) => (props: any) => {
  const C = lazy(factory);
  return (
    <Suspense fallback={<SuspenseLoader />}>
      <C {...props} />
    </Suspense>
  );
};

export default LazyLoader;
