import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FC, useState, createContext, useContext } from 'react';

interface ISidebarContext {
  sidebarToggle: boolean;
  isPerm: boolean;
  toggleSidebar: () => void;
  closeSidebar: () => void;
}

const SidebarContext = createContext<ISidebarContext>({} as ISidebarContext);

const SidebarProvider: FC = ({ children }) => {
  const theme = useTheme();
  const isPerm = useMediaQuery(theme.breakpoints.up('md'));
  const [sidebarToggle, setSidebarToggle] = useState<boolean>(isPerm);

  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const closeSidebar = () => {
    setSidebarToggle(false);
  };

  return (
    <SidebarContext.Provider value={{ sidebarToggle, isPerm, toggleSidebar, closeSidebar }}>
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebar = (): ISidebarContext => useContext(SidebarContext);

export default SidebarProvider;
