import { Avatar as MUIAvatar } from '@mui/material';

import { stringToColor } from 'src/utils';

export interface IAvatarProps {
  given_name: string;
  family_name: string;
  image: string;
}

const Avatar = (props: IAvatarProps) => (
  <MUIAvatar sx={{ width: 36, height: 36 }}
    {...(props.image
      ? {
          alt: props.given_name,
          variant: 'rounded',
          src: props.image
        }
      : {
          variant: 'circular',
          sx: {
            bgcolor: stringToColor(props.given_name)
          },
          children: `${props.given_name?.charAt(0) || ''}${props.family_name?.charAt(0) || ''}`
        })}
  />
);

export default Avatar;
