import { useAuthenticator } from '@aws-amplify/ui-react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { Amplify, Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAPI } from 'src/contexts/APIContext';

const TermsOfService = ({ children }) => {
  const { dashboard } = useAPI();
  const [checked, setChecked] = useState(false);
  const [tos, setTos] = useState(null);
  const [open, setOpen] = useState(false);
  const { user } = useAuthenticator((context) => [context.user]);
  const [confirmed, setConfirmed] = useState(false);
  // internationalization
  const { t } = useTranslation();

  useEffect(() => {
    if (!dashboard) return;
  
    // fetch the latest version of the TOS once
    if (user.attributes['custom:tos_version'] >= dashboard.tosVersion) {
      setOpen(false);
      setConfirmed(true);
      return;
    }
    setOpen(true);
    Amplify.Storage.get(`tos/versions/${dashboard.tosVersion}.htm`, { level: 'public' }).then((result) => {
      setTos(result);
    });
  }, [dashboard]);

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  const handleConfirmClick = () => {
    if (checked) {
      Auth.updateUserAttributes(user, { 'custom:tos_version': dashboard.tosVersion })
        .catch((err) => console.log(err))
        .then(() => setOpen(false));
      setConfirmed(true);
    }
  };
  const handleClose = (event: object, reason: string) => {
    // if the user clicks outside the dialog, don't close it
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return;
    }
    Auth.signOut();
  };

  return (
    <>
      {confirmed && children}
      {!confirmed && dashboard && (
        <Dialog
          fullWidth={true}
          maxWidth={'sm'}
          open={open}
          onClose={handleClose}
          disableEscapeKeyDown={true}
        >
          <DialogTitle sx={{ fontWeight: 'bold' }}>
            {t('termsOfService.title')} v{dashboard.tosVersion}
          </DialogTitle>
          <DialogContent>
            <Grid container item direction="column">
              <Grid item maxHeight="500px" height={{ md: '500px', sm: '300px' }}>
                {tos && <iframe src={tos} height="100%" width="100%" />}
              </Grid>
              <Grid item>
                <p style={{ fontWeight: 'bold' }}> {t('termsOfService.desc')}</p>
                <label>
                  <input type="checkbox" checked={checked} onChange={handleCheckboxChange} />
                  {t('termsOfService.checkbox')}
                </label>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                Auth.signOut();
              }}
            >
              {t('label.decline')}
            </Button>
            <Button onClick={handleConfirmClick} disabled={!checked} variant="contained" color="primary">
              {t('label.accept')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default TermsOfService;
