import { Box, IconButton, Divider, Drawer, styled, useTheme } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import { useSidebar } from 'src/contexts/SidebarContext';

import SidebarMenu from './SidebarMenu';

const SidebarWrapper = styled(Box)(({ theme }) => ({
  width: theme.sidebar.width,
  minWdth: theme.sidebar.width,
  position: 'relative',
  height: '100%'
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 2),
  height: `calc(${theme.header.height} - 1px)`
}));

function Sidebar() {
  const { sidebarToggle, isPerm, closeSidebar, toggleSidebar } = useSidebar();
  const theme = useTheme();

  return (
    <Drawer
      variant={isPerm ? 'permanent' : 'temporary'}
      open={sidebarToggle}
      onClose={closeSidebar}
      elevation={1}
      sx={{
        width: theme.sidebar.width,
        flexShrink: 0,
        boxShadow: `${theme.sidebar.boxShadow}`,
        overflow: 'auto',
        zIndex: isPerm ? theme.zIndex.drawer : theme.zIndex.drawer + 2,
        [`& .MuiDrawer-paper`]: {
          // Compensate 1px of Drawer's right border
          width: `calc(${theme.sidebar.width} + 1px)`,
          boxSizing: 'border-box'
        }
      }}
    >
      <SidebarWrapper
        sx={{
          background: theme.sidebar.background
        }}
      >
        <DrawerHeader>
          <IconButton color="primary" onClick={toggleSidebar} sx={{ display: { sm: 'flex', md: 'none' } }}>
            <KeyboardArrowLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Divider
          sx={{
            mx: theme.spacing(2),
            background: theme.palette.primary.main
          }}
        />
        <SidebarMenu />
      </SidebarWrapper>
    </Drawer>
  );
}

export default Sidebar;
