import Avatar, { IAvatarProps } from 'src/components/Avatar';
import {
  Box,
  Button,
  Divider,
  Hidden,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
  styled,
  useTheme
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';

import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import { NavLink } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useS3Image } from 'src/hooks/ImageLoaderHook';
import { useTranslation } from 'react-i18next';

const UserBoxButton = styled(Button)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1)
}));

const MenuUserBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2)
}));

const UserBoxText = styled(Box)(({ theme }) => ({
  textAlign: 'left',
  paddingLeft: theme.spacing(1)
}));

function HeaderUserbox() {
  const theme = useTheme();
  const { t } = useTranslation();
  const ref = useRef<any>(null);
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const urlLoader = useS3Image();
  const [avatarProps, setAvatarProps] = useState<IAvatarProps>({
    given_name: user.attributes.given_name,
    family_name: user.attributes.family_name,
    image: ''
  });
  const [isOpen, setOpen] = useState<boolean>(false);

  useEffect(() => {
    urlLoader('avatar', user.attributes.picture)
      .then((url) => setAvatarProps({ ...avatarProps, image: url }))
      .catch(console.error);
  }, [user]);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <UserBoxButton sx={{ height: theme.header.height }} ref={ref} onClick={handleOpen}>
        <Avatar {...avatarProps} />
        <Hidden mdDown>
          <UserBoxText>
            <Typography variant="h6">
              {user.attributes.given_name} {user.attributes.family_name}
            </Typography>
            <Typography variant="body2">{user.attributes['custom:company']}</Typography>
          </UserBoxText>
          <ExpandMoreTwoToneIcon color="primary" sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          <Avatar {...avatarProps} />
          <UserBoxText>
            <Typography variant="h5">
              {user.attributes.given_name} {user.attributes.family_name}
            </Typography>
            <Typography variant="body1">{user.attributes['custom:company']}</Typography>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        <List sx={{ p: 1 }} component="nav">
          <ListItem button onClick={handleClose} to="/profile/settings" component={NavLink}>
            <AccountTreeTwoToneIcon color="primary" fontSize="small" />
            <ListItemText primary={t('label.accountSettings')} />
          </ListItem>
        </List>
        <Divider />
        <Box sx={{ m: 1 }}>
          <Button
            color="primary"
            fullWidth
            onClick={() => {
              handleClose();
              signOut();
            }}
          >
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            Sign out
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
