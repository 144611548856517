import {
  Box,
  List,
  ListItem,
  useTheme,
  ListItemText,
  ListItemButton,
  ListItemIcon,
} from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import QuestionMarkRoundedIcon from '@mui/icons-material/QuestionMarkRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import DownloadIcon from '@mui/icons-material/Download';

import { useSidebar } from 'src/contexts/SidebarContext';
import { useTranslation } from 'react-i18next';
import SidebarMenuTree from './SidebarMenuTree';

const SidebarMenu = () => {
  const { closeSidebar } = useSidebar();
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column" height={`calc(100vh - ${theme.header.height} - 1px)`}>
      <List>
        <ListItem component={RouterLink} to={`/`} disableGutters disablePadding>
          <ListItemButton onClick={closeSidebar} divider>
            <ListItemIcon>
              <HomeRoundedIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary={t('label.home')} primaryTypographyProps={{ variant: 'h5' }} />
          </ListItemButton>
        </ListItem>
      </List>

      <SidebarMenuTree />

      <List>
        <ListItem component={RouterLink} to={`/download`} disableGutters disablePadding>
          <ListItemButton onClick={closeSidebar} divider>
            <ListItemIcon>
              <DownloadIcon color='primary' />
            </ListItemIcon>
            <ListItemText primary={t('label.download')} primaryTypographyProps={{ variant: 'h5' }} />
          </ListItemButton>
        </ListItem>
        <ListItem component={RouterLink} to={`/faq`} disableGutters disablePadding>
          <ListItemButton onClick={closeSidebar} divider>
            <ListItemIcon>
              <QuestionMarkRoundedIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary={t('label.faq')} primaryTypographyProps={{ variant: 'h5' }} />
          </ListItemButton>
        </ListItem>
        <ListItem disableGutters disablePadding>
          <ListItemButton
            onClick={() => {
              window.location.href = 'mailto:' + t('contact.email');
              closeSidebar();
            }}
            divider
          >
            <ListItemIcon>
              <SupportAgentRoundedIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary={t('label.contact')} primaryTypographyProps={{ variant: 'h5' }} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
}

export default SidebarMenu;
