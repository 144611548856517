import { CUnitItem, StripItem } from '.';
import { type Object, State, Threshold, Zone } from '../models/API';

export enum ActivityPriority {
  archived = 1,
  pending = 2,
  off = 3,
  no = 4,
  moderate = 5,
  significant = 6
}

export enum ActivitySortOrder {
  archived = 1,
  no = 2,
  moderate = 3,
  significant = 4,
  pending = 5,
  off = 6
}

export type Activity = keyof typeof ActivityPriority;

export type ActivityPoint = {
  datetime: Date;
  activity: Activity;
};

export type ActivityHistory = {
  activities: {
    zone: Zone;
    history: ActivityPoint[];
  }[];
  from: Date;
  to: Date;
};

type ActivityStats = {
  [status in Activity]: number;
};

export type Stats = {
  activity: Omit<ActivityStats, 'archived'>;
  total: {
    cUnit: number;
    strip: number;
  };
};

export const activities: Activity[] = Object.keys(ActivityPriority).filter(
  (a) => isNaN(Number(a)) && a != 'archived'
) as Activity[];

const getActivityFromState = (state: State | undefined): Activity | null => {
  if (!state) return null;
  if ([State.DISABLED, State.LOOSE, State.BROKEN].includes(state)) return 'off';
  if (state === State.MAINTENANCE) return 'pending';
  if (state === State.ARCHIVED) return 'archived';
  return null;
};

const getActivityFromDatetime = (datetime: string | undefined): Activity | null => {
  if (!datetime) return null;

  const today = new Date();
  const lastReport = new Date(datetime);
  const diffInHours = Math.abs(today.getTime() - lastReport.getTime()) / (1000 * 60 * 60);
  if (diffInHours > 48) return 'off';
};

const getActivityFromValue = (value: number, threshold: Threshold, watermelon: boolean): Activity => {
  if (value === -1.0 || value === null) return 'pending';
  if (watermelon) return 'no';
  if (value < threshold.lower) return 'no';
  if (threshold.lower <= value && value < threshold.upper) return 'moderate';
  if (value >= threshold.upper) return 'significant';
  return 'pending';
};

export const getActivity = (
  value: number,
  threshold: Threshold,
  watermelon: boolean,
  state?: State,
  datetime?: string
): Activity =>
  getActivityFromState(state) ||
  getActivityFromDatetime(datetime) ||
  getActivityFromValue(value, threshold, watermelon);

export const getObjectState = (object: Object, cUnit: CUnitItem, strip: StripItem): State =>
  stateComparator(object.state, getCUnitState(cUnit, strip));
export const getCUnitState = (cUnit: CUnitItem, strip: StripItem): State =>
  stateComparator(cUnit.item.state, strip.item.state);

export const activityPriorityComparator = (a: Activity, b: Activity): number => {
  const prioA = ActivityPriority[a];
  const prioB = ActivityPriority[b];

  if (prioA > prioB) return 1;
  if (prioA < prioB) return -1;
  return 0;
};

export const activitySortComparator = (a: Activity, b: Activity): number => {
  const prioA = ActivitySortOrder[a];
  const prioB = ActivitySortOrder[b];

  if (prioA > prioB) return 1;
  if (prioA < prioB) return -1;
  return 0;
};

const stateComparator = (a: State, b: State): State => {
  return [State.ACTIVE, State.HIDDEN].includes(a) ? b : a;
};

export const activityReducer = (activities: Activity[], state?: State): Activity =>
  getActivityFromState(state) || activities.length
    ? activities.reduce((prev, curr) => (activityPriorityComparator(prev, curr) > 0 ? prev : curr))
    : 'pending';
