declare global {
  interface String {
    toCapitalCase(): string;
  }
}

/**
 * Capitalizes the first letter of the string and converts the rest of the string to lowercase.
 * @returns A new string with the first letter capitalized and the rest in lowercase.
 */
String.prototype.toCapitalCase = function (): string {
  return this.charAt(0).toUpperCase() + this.slice(1).toLowerCase();
};

export {};
